:root {
  --bg: #f7f7f9;
  --dark: rgba(22, 26, 32);
  --dark-2: rgba(22, 26, 32, 0.02);
  --dark-3: rgba(22, 26, 32, 0.03);
  --dark-5: rgba(22, 26, 32, 0.05);
  --dark-10: rgba(22, 26, 32, 0.1);
  --dark-15: rgba(22, 26, 32, 0.15);
  --dark-30: rgba(22, 26, 32, 0.3);
  --dark-40: rgba(22, 26, 32, 0.4);
  --dark-50: rgba(22, 26, 32, 0.5);
  --dark-70: rgba(22, 26, 32, 0.7);
  --light: rgba(255, 255, 255, 1);
  --light-10: rgba(255, 255, 255, 0.1);
  --light-30: rgba(255, 255, 255, 0.3);
  --light-70: rgba(255, 255, 255, 0.7);
  --light-80: rgba(255, 255, 255, 0.8);
  --danger: rgba(255, 74, 62);
  --danger-10: rgba(255, 74, 62, 0.1);
  --danger-20: rgba(255, 74, 62, 0.2);
  --danger-dark: rgba(230, 68, 58);
  --primary: rgba(44, 112, 220);
  --primary-5: rgba(44, 112, 220, 0.05);
  --primary-10: rgba(44, 112, 220, 0.1);
  --primary-15: rgba(44, 112, 220, 0.15);
  --primary-20: rgba(44, 112, 220, 0.2);
  --primary-30: rgba(44, 112, 220, 0.3);
  --primary-50: rgba(44, 112, 220, 0.5);
  --primary-80: rgba(44, 112, 220, 0.8);
  --primary-dark: rgba(26, 43, 70);
  --primary-dark-3: rgba(26, 43, 70, 0.03);
  --primary-dark-5: rgba(26, 43, 70, 0.05);
  --primary-dark-15: rgba(26, 43, 70, 0.15);
  --primary-dark-30: rgba(26, 43, 70, 0.3);
  --primary-dark-40: rgba(26, 43, 70, 0.4);
  --success: rgba(69, 227, 180);
  --success-10: rgba(69, 227, 180, 0.1);
  --success-15: rgba(69, 227, 180, 0.15);
  --success-20: rgba(69, 227, 180, 0.2);
  --success-dark: rgb(64, 188, 151);
  --warning: rgba(255, 196, 43);
  --warning-10: rgba(255, 196, 43, 0.1);
  --warning-20: rgba(255, 196, 43, 0.2);
  --warning-dark: rgba(187, 147, 43);
  --purple: rgba(147, 51, 243);
  --purple-15: rgba(147, 51, 243, 0.15);
  --secondary: rgba(208, 221, 242);
  --secondary-40: rgba(208, 221, 242, 0.4);
  --secondary-dark: rgba(62, 81, 112, 1);
  --secondary-dark-10: rgba(62, 81, 112, 0.1);
  --info: rgba(102, 237, 255);
  --aqua: rgba(102, 226, 166);
  --tropic: rgba(149, 219, 116);
  --derm: rgba(255, 166, 102);
  --pompelmo: rgba(255, 102, 102);
  --c1: #00adb5;
  --c2: #8785a2;
  --c3: #3f72af;
  --c4: #e84545;
  --c5: #be9fe1;
  --c6: #ffde7d;
  --c7: rgba(211, 78, 78, 1);
  --c8: rgba(224, 158, 59, 1);
  --c9: rgba(255, 229, 139, 1);
  --c10: rgba(239, 233, 75, 1);
  --c11: rgba(196, 244, 92, 1);
  --c12: rgba(146, 214, 78, 1);
  --c13: rgba(134, 255, 146, 1);
  --c14: rgba(66, 246, 84, 1);
  --c15: rgba(72, 205, 101, 1);
  --c16: rgba(102, 226, 166, 1);
  --c17: rgba(149, 219, 116, 1);
  --c18: rgba(102, 237, 255, 1);
  --c19: rgba(59, 203, 213, 1);
  --c20: rgba(74, 147, 216, 1);
  --c21: rgba(75, 102, 242, 1);
  --c22: rgba(145, 78, 255, 1);
  --c23: rgba(218, 58, 244, 1);
  --c24: rgba(255, 102, 230, 1);
  --c25: rgba(255, 166, 102, 1);
  --c26: rgba(255, 102, 102, 1);
  --c27: rgba(178, 45, 61, 1);
  --c28: rgba(103, 25, 53, 1);
}
