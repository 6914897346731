.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--light);
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  transition: 0.2s linear;
}

.icon-xs {
  width: 16px;
  height: 16px;
}

.icon-sm {
  width: 20px;
  height: 20px;
}

.icon-lg {
  width: 30px;
  height: 30px;
}

.icon-big {
  width: 40px;
  height: 40px;
}

.icon-search {
  mask-image: url("../icon/search-md.svg");
  -webkit-mask-image: url("../icon/search-md.svg");
}
.icon-refresh {
  mask-image: url("../icon/refresh.svg");
  -webkit-mask-image: url("../icon/refresh.svg");
}

.icon-car {
  mask-image: url("../icon/car.svg");
  -webkit-mask-image: url("../icon/car.svg");
}

.icon-bell {
  mask-image: url("../icon/bell.svg");
  -webkit-mask-image: url("../icon/bell.svg");
}

.icon-pizza {
  mask-image: url("../icon/pizza.svg");
  -webkit-mask-image: url("../icon/pizza.svg");
}

.icon-analytic {
  mask-image: url("../icon/analytic.svg");
  -webkit-mask-image: url("../icon/analytic.svg");
}

.icon-nav-link-bg {
  mask-image: url("../icon/nav-link-bg.svg");
  -webkit-mask-image: url("../icon/nav-link-bg.svg");
}

.icon-arrow-right {
  mask-image: url("../icon/arrow-right.svg");
  -webkit-mask-image: url("../icon/arrow-right.svg");
}

.icon-arrow-left {
  mask-image: url("../icon/arrow-right.svg");
  -webkit-mask-image: url("../icon/arrow-right.svg");
  transform: rotate(180deg);
}

.icon-booking {
  mask-image: url("../icon/booking.svg");
  -webkit-mask-image: url("../icon/booking.svg");
}

.icon-parking {
  mask-image: url("../icon/parking.svg");
  -webkit-mask-image: url("../icon/parking.svg");
}

.icon-doc {
  mask-image: url("../icon/doc.svg");
  -webkit-mask-image: url("../icon/doc.svg");
}

.icon-currency-ruble {
  mask-image: url("../icon/currency-ruble.svg");
  -webkit-mask-image: url("../icon/currency-ruble.svg");
}

.icon-flip-forward-right {
  mask-image: url("../icon/flip-forward-right.svg");
  -webkit-mask-image: url("../icon/flip-forward-right.svg");
}

.icon-chevron-right {
  mask-image: url("../icon/chevron-right.svg");
  -webkit-mask-image: url("../icon/chevron-right.svg");
}

.icon-chevron-up {
  mask-image: url("../icon/chevron-right.svg");
  -webkit-mask-image: url("../icon/chevron-right.svg");
  transform: rotate(-90deg);
}

.icon-chevron-down {
  mask-image: url("../icon/chevron-right.svg");
  -webkit-mask-image: url("../icon/chevron-right.svg");
  transform: rotate(90deg);
}

.icon-circle-line {
  mask-image: url("../icon/circle-line.svg");
  -webkit-mask-image: url("../icon/circle-line.svg");
}

.icon-close {
  mask-image: url("../icon/close.svg");
  -webkit-mask-image: url("../icon/close.svg");
}

.icon-upload {
  mask-image: url("../icon/upload.svg");
  -webkit-mask-image: url("../icon/upload.svg");
}

.icon-wallet {
  mask-image: url("../icon/wallet.svg");
  -webkit-mask-image: url("../icon/wallet.svg");
}

.icon-download {
  mask-image: url("../icon/download.svg");
  -webkit-mask-image: url("../icon/download.svg");
}

.icon-pdf {
  mask-image: url("../icon/pdf.svg");
  -webkit-mask-image: url("../icon/pdf.svg");
}

.icon-reverse-left {
  mask-image: url("../icon/reverse-left.svg");
  -webkit-mask-image: url("../icon/reverse-left.svg");
}

.icon-calendar {
  mask-image: url("../icon/calendar.svg");
  -webkit-mask-image: url("../icon/calendar.svg");
}

.icon-home {
  mask-image: url("../icon/home.svg");
  -webkit-mask-image: url("../icon/home.svg");
}

.icon-tool {
  mask-image: url("../icon/tool.svg");
  -webkit-mask-image: url("../icon/tool.svg");
}

.icon-tools {
  mask-image: url("../icon/tools.svg");
  -webkit-mask-image: url("../icon/tools.svg");
}

.icon-phone {
  mask-image: url("../icon/phone.svg");
  -webkit-mask-image: url("../icon/phone.svg");
}

.icon-navigation {
  mask-image: url("../icon/navigation.svg");
  -webkit-mask-image: url("../icon/navigation.svg");
}

.icon-shield {
  mask-image: url("../icon/notif-otchet.svg");
  -webkit-mask-image: url("../icon/notif-otchet.svg");
}

.icon-aprove-report {
  mask-image: url("../icon/aprove-the-report.svg");
  -webkit-mask-image: url("../icon/aprove-the-report.svg");
}

.icon-plus-circle {
  mask-image: url("../icon/plus-circle.svg");
  -webkit-mask-image: url("../icon/plus-circle.svg");
}

.icon-minus-circle {
  mask-image: url("../icon/minus-circle.svg");
  -webkit-mask-image: url("../icon/minus-circle.svg");
}

.icon-file {
  mask-image: url("../icon/file.svg");
  -webkit-mask-image: url("../icon/file.svg");
}

.icon-file-check {
  mask-image: url("../icon/file-check.svg");
  -webkit-mask-image: url("../icon/file-check.svg");
}

.icon-bar-line-chart {
  mask-image: url("../icon/bar-line.svg");
  -webkit-mask-image: url("../icon/bar-line.svg");
}

.icon-check {
  mask-image: url("../icon/check.svg");
  -webkit-mask-image: url("../icon/check.svg");
}

.icon-chevron-selector {
  mask-image: url("../icon/chevron-selector-vertical.svg");
  -webkit-mask-image: url("../icon/chevron-selector-vertical.svg");
}

.icon-filter-lines {
  mask-image: url("../icon/filter-lines-down.svg");
  -webkit-mask-image: url("../icon/filter-lines-down.svg");
}

.icon-comment-reject {
  mask-image: url("../icon/message-dots-circle.svg");
  -webkit-mask-image: url("../icon/message-dots-circle.svg");
}

.icon-nike {
  mask-image: url("../icon/check-okey-reject.svg");
  -webkit-mask-image: url("../icon/check-okey-reject.svg");
}

.icon-percent-circle {
  mask-image: url("../icon/percent-circle.svg");
  -webkit-mask-image: url("../icon/percent-circle.svg");
}

.icon-receipt-check {
  mask-image: url("../icon/receipt-check.svg");
  -webkit-mask-image: url("../icon/receipt-check.svg");
}

.icon-plus {
  mask-image: url("../icon/plus.svg");
  -webkit-mask-image: url("../icon/plus.svg");
}

.icon-minus {
  mask-image: url("../icon/minus.svg");
  -webkit-mask-image: url("../icon/minus.svg");
}

.icon-alert-circle {
  mask-image: url("../icon/alert-circle.svg");
  -webkit-mask-image: url("../icon/alert-circle.svg");
}

.icon-calendar-tool {
  mask-image: url("../icon/calendar-tool.svg");
  -webkit-mask-image: url("../icon/calendar-tool.svg");
}

.icon-arrow-narrow-right {
  mask-image: url("../icon/arrow-narrow-right.svg");
  -webkit-mask-image: url("../icon/arrow-narrow-right.svg");
}

.icon-arrow-narrow-left {
  mask-image: url("../icon/arrow-narrow-right.svg");
  -webkit-mask-image: url("../icon/arrow-narrow-right.svg");
  transform: rotate(180deg);
}

.icon-server {
  mask-image: url("../icon/server.svg");
  -webkit-mask-image: url("../icon/server.svg");
}

.icon-logout {
  mask-image: url("../icon/log-out.svg");
  -webkit-mask-image: url("../icon/log-out.svg");
}

.icon-pencil {
  mask-image: url("../icon/pencil.svg");
  -webkit-mask-image: url("../icon/pencil.svg");
}

.icon-gmail {
  mask-image: url("../icon/gmail.svg");
  -webkit-mask-image: url("../icon/gmail.svg");
}
